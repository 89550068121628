import classNames from 'classnames';

export type Props = {
  type?: string;
  placeholder?: string;
  className?: string;
  value?: string;
  onChange?: (...args: any) => void;
  onBlur?: (...args: any) => void;
  onKeyPress?: (...args: any) => void;
  id?: string;
  name?: string;
  maxLength?: number;
};

export const InputText: React.FC<Props> = ({
  id,
  type = 'text',
  name,
  className,
  placeholder,
  value,
  maxLength,
  onChange,
  onBlur,
  onKeyPress,
}) => {
  return (
    <input
      type={type}
      id={id}
      name={name}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      onKeyPress={onKeyPress}
      maxLength={maxLength}
      className={classNames(
        'border border-solid rounded-lg text-black700',
        className
      )}
    />
  );
};
