'use client';

import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { isEmailValid } from '../../../utils/helpers/validation';
import { Button } from '../../ui/button/button';
import { InnerContainer } from '../../ui/common/inner-container';
import { InputText } from '../../ui/input-text/input-text';

import { useSubscribeMutation } from '@/src/store/services/subscribe-service';
import Heading from '../../ui/heading';

const initialEmailValue = {
  pageId: 0,
  purposeId: 0,
  email: '',
  phone: '',
  userId: 0,
};

let ALERT_MSG: string;

export const EmailSubscription = () => {
  const [emailValue, setEmailValue] = useState(initialEmailValue);
  const [emailAlertStatus, setEmailAlertStatus] = useState(false);

  const [subscribeMethod, { isSuccess, isError, data, error }] = useSubscribeMutation();

  const submitHandler = (event: { preventDefault: () => void }) => {
    event.preventDefault();
  };

  const inputChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmailValue({ ...emailValue, email: e.target.value });
    setEmailAlertStatus(false);
  };

  const emailSubscriptionHandler = async () => {
    if (emailValue.email.length === 0) {
      ALERT_MSG = 'E-Posta zorunludur.';
      setEmailAlertStatus(true);
    } else if (!isEmailValid(emailValue.email)) {
      ALERT_MSG = 'Geçerli E-Posta adresi giriniz.';
      setEmailAlertStatus(true);
    } else {
      setEmailAlertStatus(false);
      await subscribeMethod(emailValue);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      setEmailAlertStatus(true);
      ALERT_MSG = data.message;
    } else if (isError) {
      setEmailAlertStatus(true);
      ALERT_MSG = error.data[0].message;
    }
  }, [isError, isSuccess, data, error]);

  return (
    <InnerContainer className="flex md:flex-row flex-col justify-between">
      <Heading
        tagType="h3"
        cls="md:w-[450px] text-black1000 text-xl md:text-2xl"
        bolded
      >{`Güncel faiz oranlarını ve finans gelişmelerini e-posta adresinizi bırakarak hemen öğrenin.`}</Heading>
      <form onSubmit={submitHandler} className="flex flex-col justify-center relative">
        <label htmlFor="emailSubscription" className="text-black700 font-semibold text-sm mb-2 mt-4">
          E-mail
        </label>
        <div className="flex md:flex-row flex-col">
          <InputText
            id={'emailSubscription'}
            className={classNames(
              'p-4 md:mr-4 md:mb-0 mb-[20px] font-bold md:h-[56px] md:w-[376px] h-[48px] w-[100%]',
              { 'border-purple500': emailAlertStatus }
            )}
            placeholder={'E-Posta adresinizi girin'}
            onChange={inputChangeHandler}
            value={emailValue.email}
          />
          <Button
            className={classNames('md:w-[152px] md:h-[56px] h-[48px] w-[100%] rounded-lg text-sm md:text-base', {})}
            text={'Gönder'}
            onClick={emailSubscriptionHandler}
          />
        </div>
        {emailAlertStatus && (
          <span className="text-purple500 font-semibold text-sm md:mb-0 mb-0 absolute md:bottom-[-20px] bottom-[50px] pt-1">
            {ALERT_MSG}
          </span>
        )}
      </form>
    </InnerContainer>
  );
};
